import EmailForm from '../email/formtemplate';
import emailjs from 'emailjs-com';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

import { BtnWhite, BtnWhiteOut } from '../shared/button';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Result,
  Row,
  Spin,
  Switch,
} from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import { H2, TextRegular } from '../shared/typography';
import React, { useState } from 'react';

const LoadingContainer = styled.div`
  min-height: 400px;
  display: flex;
`;

const StyledForm = styled(Form)`
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: ${theme.colors.brandLight.light};
  }
  .ant-form-item-label > label.ant-form-item-required::before {
    color: ${theme.colors.brandLight.light};
  }

  .ant-form-item-has-error .ant-input {
    color: ${theme.colors.brandLight.dark};
  }

  Input {
    background: ${theme.colors.brandLight.dark};
    color: ${theme.colors.brandLight.light};
  }
`;

type Props = {
  locale: string;
  privateForm: boolean;
  background?: string
};

const CompanyContactForm: React.FC<Props> = ({
  locale,
  privateForm,
  background
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isPrivateForm, setIsPrivateForm] = useState(privateForm);

  const formDataNodes = companyFormQuery();
  const formData =
    formDataNodes.nodes.find((node: any) => node.node_locale == locale) ||
    formDataNodes.nodes[0];

  const onFinish = (values: any) => {
    setLoading(true);
    const toSend: FormTemplate = {
      private: isPrivateForm,
      companyName: values.companyName,
      name: values.name,
      address: values.address,
      date: values.date?.format('MMMM DD YYYY').toString() || '',
      type: values.type,
      persons: values.persons,
      repeatly: values.repeatly ? 'yes' : 'no',
      phone: values.phone,
      email: values.email,
      comment: values.comment,
    };
    const toReply = { email: toSend.email, message_html: EmailForm(toSend) };
    emailjs
      .send('smtp_server', 'contact_form', toSend, 'user_olOiWh5cJ7Lm2YjSMkYAG')
      .then(
        function (response) {
          emailjs.send(
            'smtp_server',
            'template_KxYd07MR',
            toReply,
            'user_olOiWh5cJ7Lm2YjSMkYAG',
          );
          console.log('SUCCESS!', response.status, response.text);
          setEmailSuccess(true);
        },
        function (error) {
          setEmailError(true);
          console.log('FAILED...', error);
        },
      );
    console.log(toSend);
  };

  return (
    <Col
      span={24}
      style={{
        background: background ? background : theme.colors.brandLight.dark,
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <Row
        justify="center"
        gutter={[24, 12]}
        style={{ marginBottom: '2.75rem' }}
      >
        {/* <H2 style={{ color: 'white', textAlign: 'center' }}>
          {formData.formTitle}
        </H2> */}
        <Col xs={24} lg={8}>
          {isPrivateForm ? (
            <BtnWhite onClick={() => setIsPrivateForm(true)}>
              {'Private'}
            </BtnWhite>
          ) : (
            <BtnWhiteOut onClick={() => setIsPrivateForm(true)}>
              {'Private'}
            </BtnWhiteOut>
          )}
        </Col>
        <Col xs={24} lg={8}>
          {!isPrivateForm ? (
            <BtnWhite onClick={() => setIsPrivateForm(false)}>
              {locale === 'da-DK' ? 'Virksomhed' : 'Company'}
            </BtnWhite>
          ) : (
            <BtnWhiteOut onClick={() => setIsPrivateForm(false)}>
              {locale === 'da-DK' ? 'Virksomhed' : 'Company'}
            </BtnWhiteOut>
          )}
        </Col>
      </Row>
      {loading && (
        <LoadingContainer>
          {!emailSuccess && !emailError && (
            <Spin style={{ margin: 'auto' }} size="large" />
          )}
          {emailSuccess && (
            <Result
              style={{ margin: 'auto' }}
              status="success"
              title="Successfully send email request!"
              subTitle="We will get back to you as soon as we can!"
            />
          )}
          {emailError && (
            <Result
              style={{ margin: 'auto' }}
              status="500"
              title="Sorry, something went wrong."
              subTitle="Please try again. If the error keeps happening try call us instead"
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    setEmailError(false);
                    setLoading(false);
                  }}
                >
                  Try again
                </Button>
              }
            />
          )}
        </LoadingContainer>
      )}
      {!loading && (
        <Row>
          <Col span={24}>
            <StyledForm
              layout="horizontal"
              size="large"
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
            >
              {!isPrivateForm && (
                <Form.Item
                  name="companyName"
                  rules={[{ required: !isPrivateForm }]}
                >
                  <Input placeholder={formData.formCompanyName} />
                </Form.Item>
              )}

              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input placeholder={formData.formName} required />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form.Item name="date">
                  <DatePicker
                    style={{ background: theme.colors.brandLight.dark }}
                    placeholder={formData.formDate}
                  />
                </Form.Item>
                  <Form.Item name="type" initialValue={"PCR"} style={{marginLeft: '1rem'}}>
                    <Radio.Group
                      style={{ color: theme.colors.brandLight.light }}
                      >
                      <Radio
                        style={{ color: theme.colors.brandLight.light }}
                        value={"PCR"}
                      >
                        PCR
                      </Radio>
                      <Radio
                        style={{ color: theme.colors.brandLight.light }}
                        value={"Antigen"}
                      >
                        Antigen
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
              </div>
              {isPrivateForm && (
                <TextRegular style={{ color: 'white' }}>
                  {locale === 'da-DK'
                    ? 'Udfyld lokation hvis du ønsker testen skal foregå andetsteds end ved vores testcenter'
                    : 'Fill in the location if you want the test to take place elsewhere than at our test center'}
                </TextRegular>
              )}
              <Form.Item name="address" rules={[{ required: !isPrivateForm }]}>
                <Input placeholder={formData.formAddress} />
              </Form.Item>
              {!isPrivateForm && (
                <>
                  <TextRegular style={{ color: 'white' }}>
                    {formData.formPersons}
                  </TextRegular>
                  <Form.Item
                    name="persons"
                    rules={[
                      { required: !isPrivateForm },
                      { type: 'number', min: 10, max: 1000 },
                    ]}
                    initialValue={10}
                  >
                    <InputNumber placeholder={formData.formPersons} />
                  </Form.Item>
                </>
              )}
              {!isPrivateForm && (
                <>
                  <TextRegular style={{ color: 'white' }}>
                    {formData.formRepeatly}
                  </TextRegular>
                  <Form.Item name="repeatly">
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="email"
                rules={[{ type: 'email' }, { required: true }]}
              >
                <Input placeholder={formData.formEmail} />
              </Form.Item>
              <Form.Item name="phone" rules={[{ required: true }]}>
                <Input placeholder={formData.formPhone} />
              </Form.Item>
              <Form.Item name={['comment']}>
                <Input.TextArea
                  style={{
                    background: theme.colors.brandLight.dark,
                    color: theme.colors.brandLight.light,
                  }}
                  placeholder={formData.formComment}
                />
              </Form.Item>

              <Row justify="center">
                <Col xs={24} lg={12}>
                  <BtnWhite type="submit" disabled={loading}>
                    {formData.formBtn}
                  </BtnWhite>
                </Col>
              </Row>
            </StyledForm>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default CompanyContactForm;

export interface FormTemplate {
  private: boolean;
  companyName?: string;
  name: string;
  address: string;
  persons: number;
  date: string;
  type: string;
  repeatly: string;
  phone: string;
  email: string;
  comment: string;
}

export const companyFormQuery = () => {
  const data = useStaticQuery(graphql`
    query CompanyFormQuery {
      allContentfulCompanyForm {
        nodes {
          formTitle
          formRepeatly
          formPhone
          formPersons
          formName
          formEmail
          formDate
          formCompanyName
          formComment
          formBtn
          formAddress
          node_locale
        }
      }
    }
  `);
  return data.allContentfulCompanyForm;
};
