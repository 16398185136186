import { MenuOutlined } from '@ant-design/icons';
import navigateTo from '../../locales/pathHandler';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

import { Dropdown, Menu } from 'antd';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { INavigation, INavigationData } from '../shared/contentfu.interface';
import React, { useEffect, useState } from 'react';

const Container = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  background: #d9f2f4;
  z-index: 1000;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  height: fit-content;
`;

const StyledMenu = styled(Menu)`
  padding: 0.5rem;
  li {
    padding-bottom: 0.5rem;
  }
`;

const Nav = styled.div`
  min-width: 600px;
  max-width: calc(1366px + 2rem);
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1600px) {
    padding: 0 50px;
  }
  @media (max-width: 950px) {
    min-width: unset;
    flex-wrap: wrap;
    height: fit-content;
    padding: 0 25px;
  }
`;

const StyledMenuIcon = styled(MenuOutlined)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${theme.colors.brandLight.dark};
  font-size: 30px;
`;

const StyledLink = styled(Link)`
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.5rem 1rem;
  line-height: 1rem;
  color: ${theme.colors.brandLight.dark};
  font-size: 18px;
  display: flex;
  justify-content: center;

  &:hover {
    color: ${theme.colors.brandLight.primary};
  }

  @media (max-width: 1100px) {
    padding: 0.5rem;
  }

  @media (max-width: 949px) {
    padding: 1rem;
  }
`;


const NavTabContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
`;

const NavTab = styled.div`
  width: fit-content;
  margin: auto;
  text-align: center;
  cursor: pointer;
`;

const StyledImg = styled.img`
  height: 120px;
  width: auto;
  @media (max-width: 950px) {
    height: 80px;
    width: auto;
  }
`;

type Props = {
  locale: string;
  component: string;
};

const Navigation: React.FC<Props> = ({ locale, component }: Props) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const navigationDataNodes: INavigationData = navigationQuery();
  const navigationData: INavigation =
    navigationDataNodes.nodes.find((node) => node.node_locale == locale) ||
    navigationDataNodes.nodes[0];
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menu = (
    <StyledMenu mode="horizontal">
       <Menu.Item>
        <StyledLink to={locale === 'da-DK' ? '/' : '/en/'}>
          {'Home'}
        </StyledLink>
      </Menu.Item>
      <Menu.Item>
        <StyledLink to={locale === 'da-DK' ? '/booking' : '/en/booking'}>
          {navigationData.link1}
        </StyledLink>
      </Menu.Item>
      <Menu.Item>
        <StyledLink to={locale === 'da-DK' ? '/virksomhed' : '/en/company'}>
          {navigationData.link2}
        </StyledLink>
      </Menu.Item>
      <Menu.Item>
        <StyledLink to={locale === 'da-DK' ? '/kontakt' : '/en/contact'}>
          {navigationData.link3}
        </StyledLink>
      </Menu.Item>
      <Menu.Item>
        <StyledLink to={locale === 'da-DK' ? '/om' : '/en/about'}>
          {navigationData.link4}
        </StyledLink>
      </Menu.Item>
    </StyledMenu>
  );

  return (
    <Container>
      <Nav>
        <StyledLink
          style={{ padding: '0' }}
          to={locale === 'da-DK' ? '/' : '/en'}
        >
          <StyledImg
            src={'/images/logo-full-color.png'}
            alt={'PrivatCoronaTest logo'}
          />
        </StyledLink>
        {windowWidth >= 950 && (
          <NavTabContainer>
            <NavTab>
              <StyledLink to={locale === 'da-DK' ? '/booking' : '/en/booking'}>
                {navigationData.link1}
              </StyledLink>
            </NavTab>
            <NavTab>
              <StyledLink
                to={locale === 'da-DK' ? '/virksomhed' : '/en/company'}
              >
                {navigationData.link2}
              </StyledLink>
            </NavTab>
            <NavTab>
              <StyledLink to={locale === 'da-DK' ? '/kontakt' : '/en/contact'}>
                {navigationData.link3}
              </StyledLink>
            </NavTab>
            <NavTab>
              <StyledLink to={locale === 'da-DK' ? '/om' : '/en/about'}>
                {navigationData.link4}
              </StyledLink>
            </NavTab>
            <img
              src={
                locale === 'da-DK'
                  ? navigationData.enFlag.file.url
                  : navigationData.daFlag.file.url
              }
              alt={
                locale === 'da-DK'
                  ? navigationData.enFlag.file.fileName
                  : navigationData.daFlag.file.fileName
              }
              onClick={() =>
                navigateTo(
                  { locale: locale === 'da-DK' ? 'en-US' : 'da-DK' },
                  component,
                )
              }
              style={{
                margin: 'auto',
                cursor: 'pointer',
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: '2rem',
                objectFit: 'cover',
              }}
            />
          </NavTabContainer>
        )}
        {windowWidth < 950 && (
          <NavTabContainer>
            {windowWidth > 500 && (
              <NavTab>
                <StyledLink
                  to={locale === 'da-DK' ? '/booking' : '/en/booking'}
                >
                  {navigationData.link1}
                </StyledLink>
              </NavTab>
            )}
            <img
              src={
                locale != 'da-DK'
                  ? navigationData.daFlag.file.url
                  : navigationData.enFlag.file.url
              }
              alt={
                locale != 'da-DK'
                  ? navigationData.daFlag.file.fileName
                  : navigationData.enFlag.file.fileName
              }
              onClick={() =>
                navigateTo(
                  { locale: locale === 'da-DK' ? 'en-US' : 'da-DK' },
                  component,
                )
              }
              style={{
                margin: 'auto',
                marginRight: '1.5rem',
                cursor: 'pointer',
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: '2rem',
                objectFit: 'cover',
              }}
            />
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            >
              <StyledMenuIcon />
            </Dropdown>
          </NavTabContainer>
        )}
      </Nav>
    </Container>
  );
};

export default Navigation;

export const navigationQuery = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulNavigation {
        nodes {
          link1
          link2
          link3
          link4
          node_locale
          title
          enFlag {
            file {
              fileName
              url
            }
          }
          daFlag {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  `);
  return data.allContentfulNavigation;
};
