
//COLOR SCHEME 

enum bgLight {
    white = '#ffffff',
    black =  '#1c1c1e'
}

enum txtLight {
    white = '#ffffff',
    black =  '#1c1c1e'
}

enum greyLight {
    grey55 = "#828283",
    grey25 = "#c6c6c7",
    grey15 = "#DDDDDD",
    grey10 = "#E8E8E9",
    grey5 =  "#F4F4F4"
}

enum rainbowLight {
    red = "#ED4956",
    green = "#00ff00"
}

enum brandLight {
    primary = "#5C9EAA",
    secoundary = "#95C0BF",
    third = "#296379",
    forth = "#E0EBED",
    light = "#FFFFFF",
    dark = '#1C4453'
}

const colors = {
    bgLight,
    txtLight,
    greyLight,
    rainbowLight,
    brandLight,
    
}

enum effects {
    shadow01 = "0px 4px 16px rgba(28, 28, 30, 0.12)"
}

enum spacing {
    "3xsmall" = "2px",
    "2xsmall" = "4px",
    "xsmall" = "8px",
    "small" = "12px",
    "medium" = "16x",
    "large" = "24px",
    "xlarge" = "32px",
    "2xlarge" = "40px",
    "3xlarge" = "48px",
    "4xlarge" = "64px",
    "5xlarge" = "96px",
}

export const theme = {
    colors,
    effects,
    spacing
}


