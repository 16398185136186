import FooterComponent from './footer';
import { INavigation } from '../shared/contentfu.interface';
import Navigation from './navigation';
import React from 'react';
import Seo from '../Seo';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

import { Col, Layout, Row } from 'antd';
import Img, { GatsbyImageFluidProps } from "gatsby-image"

import "@fontsource/merriweather"

type Props = {
  hasHeader?: boolean;
  headerImg?: GatsbyImageFluidProps;
  headerText?: string;
  headerMainBtn?: string;
  headerSecondaryBtn?: string;
  headerAlign?: string;
  navigationContext?: INavigation;
  locale: string;
  componentName: string;
};

const SiteLayout: React.FC<Props> = props => {

  const HomePageHeader = styled(Img)`
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    Col {
      max-width: calc(1366px + 2rem);
    }
  `;

  const StyledCoverText = styled.div`
    z-index: 3;
    width: 100%;
    height: calc(80vh - 120px);
    position: relative;
    @media (max-width: 700px) {
      height: fit-content;
    }
  `;

const StyledCoverRow = styled(Row)`
    height: 100%;
    background: rgb(0,0,0, 0.15);
    position: absolute;
    z-index: 5;
    width: 100%;
    top:0;
  `
  

const StyledH1 = styled.h1`
color: white;
font-weight: 300;
width: 50%;
text-align: center;
align-self: center;
font-size: 54px;
line-height: 1.23;
@media (max-width: 700px) {
  width: 100%;
  font-size: 30px;
}
`;


  const StyledContent = styled(Layout.Content)`
    max-width: calc(1366px + 2rem);
    margin: auto auto auto auto;
    min-width: 700px;
    width: 100%;

    @media (max-width: 1200px) {
      margin: ${props.hasHeader ? "64px auto auto auto" : "auto"};
    }
    
    @media (max-width: 700px) {
      min-width: unset;
      max-width: 100vw;
    }
  `;
  const StyledLayout = styled(Layout)`
    overflow-x: hidden;
    min-height: 100vh;
    min-width: 700px;
    background: ${theme.colors.greyLight.grey5};

    @media (max-width: 700px) {
      min-width: 350px;
      max-width: 100vw;
    }
  `;

  return (
    <StyledLayout>
      <Seo title="Privat corona test" />
      <Navigation locale={props.locale} component={props.componentName} />


      {props.hasHeader && props.headerImg && (
        <StyledCoverText>
          <HomePageHeader aria-label={props.headerImg.title} fluid={props.headerImg.fluid} alt={props.headerImg.title} />
            <StyledCoverRow justify="center">
              <Col style={{display: "flex", justifyContent: "center"}} span={22} offset={0}>
                <StyledH1>{props.headerText}</StyledH1>
              </Col>
            </StyledCoverRow>
          </StyledCoverText>
      )}

      <StyledContent>
        <Row justify="center">
          <Col xs={{span:24, offset:0}} xl={{span:22, offset:0}} xxl={{span: 24, offset: 0}}>
            {props.children}
          </Col>
        </Row>
      </StyledContent>
      <FooterComponent locale={props.locale} component={props.componentName} />
    </StyledLayout>
  );
};

export default SiteLayout;