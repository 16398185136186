import { FormTemplate } from '../contact/companyForm'
import template from './email_template'

function EmailForm(form: FormTemplate) {
    var replace = ""

    if(!form.private) {
        replace = `
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Virksomhed:</b> ${form.companyName}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Kontaktperson:</b> ${form.name}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Lokation:</b> ${form.address}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Antal:</b> ${form.persons}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Ønsket dato</b>: ${form.date}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Kontinuerligt forløb</b>: ${form.repeatly}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Mobil:</b> ${form.phone}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Email:</b> ${form.email}</li>
            <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Kommentar:</b>${form.comment ? form.comment : ""}</li>
        `
    }
    else {
        replace = `
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Navn:</b> ${form.name}</li>
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Ønsket dato</b>: ${form.date}</li>
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Mobil:</b> ${form.phone}</li>
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Email:</b> ${form.email}</li>
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Lokation:</b> ${form.address}</li>
        <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;"><b>Kommentar:</b>${form.comment ? form.comment : ""}</li>
        `
    }

    var mailForm = template.replace("##REPLACE##", replace)

    return mailForm
}

export default EmailForm

{/* <li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;">lk</li>
<li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;">l</li>
<li style="font-size: 14px; line-height: 1.2; mso-line-height-alt: 17px;">øø</li> */}