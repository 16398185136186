import CompanyContactForm from '../contact/companyForm';
import styled from '@emotion/styled';
import { TextLinkTextSmall } from '../shared/button';
import { theme } from '../shared/theme';
import VisibilitySensor from 'react-visibility-sensor';

import {
  CheckOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
} from '@ant-design/icons';
import { Col, Divider, Layout, Row } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import { H1, H2, H3, TextSmall, TextXsmall } from '../shared/typography';
import {
  IFooter,
  IFooterContact,
  IFooterContactData,
  IFooterData,
} from '../shared/contentfu.interface';
import React, { Suspense, useState } from 'react';

const StyledFooter = styled(Layout.Footer)`
  background: ${theme.colors.brandLight.dark};
  width: 100%;
  z-index: 6;
  padding-top: 3rem;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const StyledHref = styled.a`
  font-size: 12px;
  color: ${theme.colors.greyLight.grey55};
  margin-bottom: 0.5rem;
`


const InnerFooter = styled(Layout.Footer)`
  background: ${theme.colors.brandLight.dark};
  padding: 0 1rem;
  width: 100%;
  max-width: calc(1366px + 2rem);
  margin: 0 auto;
  height: 100%;

  p {
    color: ${theme.colors.txtLight.white};
  }
  a {
    color: ${theme.colors.txtLight.white};
  }
`;

const SocialCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  @media(max-width: 766px) {
    justify-content: flex-start;
  }
`

const EmailCol = styled(Col)`
  text-align: right;
  @media(max-width: 766px) {
    text-align: left;
}
`


interface Props {
  locale: string;
  component: string;
}

const FooterComponent: React.FC<Props> = ({ locale, component }: Props) => {
  const data = footerQuery();
  const footerDataNodes: IFooterData = data.allContentfulFooter;
  const footerData: IFooter =
    footerDataNodes.nodes.find((node) => node.node_locale == locale) ||
    footerDataNodes.nodes[0];

  const footerDataContactNodes: IFooterContactData =
    data.allContentfulContactPage;
  const footerDataContact: IFooterContact =
    footerDataContactNodes.nodes.find((node) => node.node_locale == locale) ||
    footerDataContactNodes.nodes[0];

  const isSSR = typeof window === 'undefined';
  const Map = React.lazy(() => import('../shared/map/mapGuide'));

  const [isVisible, setVisibility] = useState(false);

  const onChange = (visiblity: boolean) => {
    visiblity && setVisibility(visiblity);
  };

  return (
    <StyledFooter>
      <InnerFooter>
        <div style={{ minHeight: '50px' }}>
          {component != '' && (
            <>
              <Row gutter={40}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ marginTop: '2rem' }}
                >
                          <H1 style={{fontSize: '35px', color: 'white', textAlign: 'center' }}>
          {locale === 'da-DK' ? 'Send forespørgsel' : 'Send request'}
        </H1>
                  <H2
                    style={{
                      textAlign: 'left',
                      color: theme.colors.brandLight.light,
                    }}
                  >
                    {footerDataContact.contactUs}
                  </H2>
                  {component != 'company' && (
                    <>
                      <p>{footerDataContact.address}</p>
                      <p>{footerDataContact.phone}</p>
                      <p>{footerDataContact.mail}</p>
                    </>
                  )}
                  {component === 'company' && (
                    <div
                      style={{ margin: '3rem 0' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          footerData.companyDescription.childContentfulRichText
                            .html,
                      }}
                    />
                  )}
                  <VisibilitySensor
                    onChange={(v) => onChange(v)}
                    partialVisibility={true}
                    scrollDelay={100}
                    intervalDelay={300}
                    offset={{ top: 500, bottom: 200 }}
                  >
                    <div style={{ height: '400px' }}>
                      {isVisible && !isSSR && (
                        <Suspense
                          fallback={
                            <TextXsmall style={{ color: 'white' }}>
                              Loading...
                            </TextXsmall>
                          }
                        >
                          <Map />
                        </Suspense>
                      )}
                    </div>
                  </VisibilitySensor>
                </Col>
                <Col xs={24} md={12}>
                  <CompanyContactForm
                    locale={locale}
                    privateForm={component != 'company'}
                  />
                </Col>
              </Row>
              <Divider
                style={{ background: theme.colors.brandLight.secoundary }}
              />
            </>
          )}
        </div>

        <Row
          style={{ marginBottom: '3rem', paddingTop: '2rem' }}
          gutter={[0, 24]}
        >
          {footerData && footerData.footerCol1 && (
            <Col xs={24} sm={12} lg={6}>
              <H3 style={{ color: theme.colors.brandLight.secoundary }}>
                {footerData.footerCol1[0]}
              </H3>
              <TextSmall>
                <CheckOutlined /> {footerData.footerCol1[1]}
              </TextSmall>
              <TextSmall>
                <CheckOutlined /> {footerData.footerCol1[2]}
              </TextSmall>
              <TextSmall>
                <CheckOutlined /> {footerData.footerCol1[3]}
              </TextSmall>
            </Col>
          )}
          {footerData && footerData.footerCol2 && (
            <Col xs={24} sm={12} lg={6}>
              <H3 style={{ color: theme.colors.brandLight.secoundary }}>
                {footerData.footerCol2[0]}
              </H3>
              <TextLinkTextSmall
                to={locale === 'da-DK' ? '/booking' : '/en/booking'}
              >
                {footerData.footerCol2[1]}
              </TextLinkTextSmall>
              <TextLinkTextSmall
                to={locale === 'da-DK' ? '/virksomhed' : '/en/company'}
              >
                {footerData.footerCol2[2]}
              </TextLinkTextSmall>
            </Col>
          )}
          {footerData && footerData.footerCol3 && (
            <Col xs={24} sm={12} lg={6}>
              <H3 style={{ color: theme.colors.brandLight.secoundary }}>
                {footerData.footerCol3[0]}
              </H3>
              <TextLinkTextSmall
                to={locale === 'da-DK' ? '/kontakt' : '/en/contact'}
              >
                {footerData.footerCol3[1]}
              </TextLinkTextSmall>
              <TextLinkTextSmall to={locale === 'da-DK' ? '/om' : '/en/about'}>
                {footerData.footerCol3[2]}
              </TextLinkTextSmall>
              <TextLinkTextSmall
                to={locale === 'da-DK' ? '/privatpolitik' : '/en/legal'}
              >
                {footerData.footerCol3[3]}
              </TextLinkTextSmall>
            </Col>
          )}
        </Row>
        <Divider style={{ background: theme.colors.brandLight.secoundary }} />
        <Row justify="space-between">
        <Col xs={{span: 24, order: 2}} md={{span: 8, order: 1}}>
            <TextSmall>{footerData.title}</TextSmall>
          </Col>
          <SocialCol xs={{span: 24, order: 1}} md={{span: 8, order: 2}} style={{marginBottom: '0.5rem'}}>
            <LinkedinFilled
              style={{
                fontSize: '18px',
                marginLeft: '0.5rem',
                color: theme.colors.brandLight.secoundary,
              }}
              onClick={() => window.open('https://' + footerData.linkedInUrl)}
            />
            <FacebookFilled
              style={{
                fontSize: '18px',
                marginLeft: '0.5rem',
                color: theme.colors.brandLight.secoundary,
              }}
              onClick={() => window.open('https://' + footerData.facebookUrl)}
            />
            <InstagramFilled
              style={{
                fontSize: '18px',
                marginLeft: '0.5rem',
                color: theme.colors.brandLight.secoundary,
              }}
              onClick={() => window.open('https://' + footerData.instagramUrl)}
            />
          </SocialCol>
          <EmailCol xs={{span: 24, order: 3}} md={{span: 8, order: 3}}>
            <TextSmall>{footerData.email}</TextSmall>
          </EmailCol>
        </Row>
        <Row justify="center">
          <StyledHref href="https://unqtech.dk">Developed by UNQTech</StyledHref>
        </Row>
      </InnerFooter>
    </StyledFooter>
  );
};

export default FooterComponent;

export const footerQuery = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulFooter {
        nodes {
          email
          facebookUrl
          instagramUrl
          linkedInUrl
          title
          footerCol1
          footerCol2
          footerCol3
          companyDescription {
            childContentfulRichText {
              html
            }
          }
          node_locale
        }
      }
      allContentfulContactPage {
        nodes {
          address
          contactUs
          mail
          phone
          node_locale
        }
      }
    }
  `);
  return data;
};
